import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FaFilm } from 'react-icons/fa'

const Header = ({ siteTitle }) => (
  <header
    style={{
      background: `#34282C`,
    }}
  >
    <div
      style={{
        margin: `0 auto`,
        maxWidth: 960,
        padding: `1.3rem 1.0875rem 0.3rem`,
      }}
    >
      <h1 style={{ margin: '0 10px 0 0', display: 'inline' }}>
        <Link
          to="/"
          style={{
            color: `#f5e1ee`,
            textDecoration: `none`,
          }}
          activeStyle={{ color: `#f6f1c0` }}
        >
          <FaFilm style={{ color: `lightblue`, marginBottom: -5, marginRight: 1 }}/>{siteTitle} <span>Vancouver</span>
        </Link>
      </h1>
      <h1 style={{ display: 'inline', margin: '0 0 0 0'}}>
      <h3>
        <Link
          to="/calendar"
          style={{
            color: `#f5e1ee`,
            textDecoration: `none`,
            textShadow: `none`,
          }}
          activeStyle={{ color: `khaki` }}
        >
        Calendar
        </Link>
        </h3>
      <h3>
        <Link
          to="/about"
          style={{
            color: `#f5e1ee`,
            textDecoration: `none`,
            textShadow: `none`,
          }}
          activeStyle={{ color: `khaki` }}
        >
        About
        </Link>
        </h3>
      <h3>
        <Link
          to="/search"
          style={{
            color: `#f5e1ee`,
            textDecoration: `none`,
            textShadow: `none`,
          }}
          activeStyle={{ color: `khaki` }}
        >
        Search
        </Link>
        </h3>
        </h1>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
